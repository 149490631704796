import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">
{/*
         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>

            <div className="ten columns">

                  <p className="lead">{message}</p>

            </div>

         </div>
*/}
         <div className="row">
           
            <div className="eight columns">
            {/*
               <form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Subject</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                  </div>

                  <div>
                     <button className="submit">Submit</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>
            */}
          
           <div id="message-warning"> Error boy</div>
				    {/* <div id="message-success">
                  <i className="fa fa-check"></i>Your message was sent, thank you!<br />
				   </div>*/}
           </div>
            

            <aside className="nine columns main-col">
                {/*  <div className="widget widget_contact">

					   <h4>Address and Phone</h4>
					   <p className="address">
						   {name}<br />
						   {street} <br />
						   {city}, {state} {zip}<br />
						   <span>{phone}</span>
					   </p>
               </div>*/}

               <div className="widget widget_tweets">
                  <h4 className="widget-title">Latest Post</h4>
                  <ul id="twitter">
                     <li>
                        <span>
                        Graduated with a degree of Master of Science in Computer Science
                        <a target="_blank" href="https://www.facebook.com/photo/?fbid=5366177763415811&set=a.145451345488505"> (Facebook-Perin, 2022)</a>
                        </span>
                        <b><a href="#">April 2, 2022</a></b>
                     </li>
                     <li>
                        <span>
                        Published Research in 2021 5th International Conference on Digital Technology in Education (ICDTE 2021). Association for Computing Machinery, New York, NY, USA, 1–7
                        <a target="_blank" href="https://dl.acm.org/doi/10.1145/3488466.3488467"> (ICDTE-ACM, 2022)</a>
                        </span>
                        <b><a href="#">January 8, 2022</a></b>
                     </li>
                     <li>
                        <span>
                         Presented Research at the 2021 5th International Conference on Digital Technology in Education
                        <a target="_blank" href="http://www.icdte.org/icdte2021.html"> (ICDTE, 2021)</a>
                        </span>
                        <b><a href="#">September 15-17, 2021</a></b>
                     </li>
                  </ul>
		         </div>
            </aside>
      </div>
   </section>
    );
  }
}

export default Contact;
