import React, { Component } from 'react';
import Typical from 'react-typical'

class Header extends Component {
  render() {
   const list_of_occupation = [
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜊᜓᜑᜓᜎᜈᜓ᜶', 1500,
      'I am  a Boholano.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜀᜄ᜔ᜐᜒᜃᜉ᜔᜶', 1500,
      'I am  an Engineer.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜀᜄ᜔ᜑᜋᜈᜓᜈ᜔᜶', 1500,
      'I am  a Scientist.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜆᜄᜉᜄᜏ᜶', 1500,
      'I am  a Programmer.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜆᜄᜉᜄᜓᜈ᜔ᜎᜇ᜔᜶', 1500,
      'I am  a Developer.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜋᜈᜈᜎᜒᜃ᜔ᜐᜒᜃ᜔᜶', 1500,
      'I am  a Researcher.', 1500,
      'ᜀᜃᜓ ᜀᜌ᜔ ᜁᜐᜅ᜔ ᜇᜎᜓᜊ᜔ᜆᜓᜇᜓ᜶', 1500,
      'I am  an Instructor.', 1500,
    ];

    if(this.props.data){
      var nickname = this.props.data.nickname;
      var name_eng = this.props.data.name_eng;
      var name_fil = this.props.data.name_fil;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">ᜊᜑᜌ᜔ | Home</a></li>
            <li><a className="smoothscroll" href="#about">ᜆᜓᜅ᜔ᜃᜓᜎ᜔ ᜐ ᜀᜃᜒᜈ᜔ | About Me</a></li>
	         <li><a className="smoothscroll" href="#resume">ᜉᜅ᜔ᜐᜇᜒᜎᜒ ᜋᜎᜃ᜔ | Resume</a></li>
            <li><a className="smoothscroll" href="#portfolio">ᜋᜅ ᜄᜏᜁᜈ᜔ | Works</a></li>
            <li><a className="smoothscroll" href="#testimonials">ᜃᜆᜒᜊᜌᜈ᜔ | Testimonials</a></li>
            <li><a className="smoothscroll" href="#contact">ᜉᜒᜈᜃᜊᜄᜓᜅ᜔ ᜄᜈᜉ᜔ | Latest post</a></li>
        </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">{nickname}</h1>
            <h2><span>{name_fil} | {name_eng} </span></h2>
            <h2><span><Typical wrapper="span" steps={list_of_occupation} loop={Infinity} className={'caca'}/></span></h2>
            <hr />
           </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

//   <li><a className="smoothscroll" href="#contact">ᜉᜃᜒᜃᜒᜉᜄ᜔ᜂᜄ᜔ᜈᜌᜈ᜔ | Contact</a></li>
/*   <ul className="social">
               {networks}
            </ul>
          */
export default Header;
