import React, { Component } from 'react';

class Sites extends Component {
  render() {

    if(this.props.data){
      var site_social = this.props.data.site_social.map(function(site_social){
        var site_socialImage = 'images/sites/'+site_social.image;
        return <div key={site_social.title} className="columns portfolio-item">
           <div className="item-wrap">
           <img width="50" height="50" alt={site_social.title} src={site_socialImage} />
            <a target="_blank" href={site_social.url} title={site_social.title}>
             
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{site_social.title}</h5>
                     <p>{site_social.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
      var site_professional = this.props.data.site_professional.map(function(site_professional){
        var site_professionalImage = 'images/sites/'+site_professional.image;
        return <div key={site_professional.title} className="columns portfolio-item">
           <div className="item-wrap">
           <img width="50" height="50" alt={site_professional.title} src={site_professionalImage} />
            <a target="_blank" href={site_professional.url} title={site_professional.title}>
             
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{site_professional.title}</h5>
                     <p>{site_professional.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
      var site_research = this.props.data.site_research.map(function(site_research){
        var site_researchImage = 'images/sites/'+site_research.image;
        return <div key={site_research.title} className="columns portfolio-item">
           <div className="item-wrap">
           <img width="50" height="50" alt={site_research.title} src={site_researchImage} />
            <a target="_blank" href={site_research.url} title={site_research.title}>
             
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{site_research.title}</h5>
                     <p>{site_research.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
      var site_development = this.props.data.site_development.map(function(site_development){
        var site_developmentImage = 'images/sites/'+site_development.image;
        return <div key={site_development.title} className="columns portfolio-item">
           <div className="item-wrap">
           <img width="50" height="50" alt={site_development.title} src={site_developmentImage} />
            <a target="_blank" href={site_development.url} title={site_development.title}>
             
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{site_development.title}</h5>
                     <p>{site_development.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="sites">

      <div className="row">

         <div className="twelve columns collapsed">

            <h1>Profiles in social media and other platforms on the internet </h1>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                }} id="portfolio-wrapper" className="social">
                {site_social}
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                }} id="portfolio-wrapper" className="social">
                {site_professional}
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                }} id="portfolio-wrapper" className="social">
                {site_research}
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                }} id="portfolio-wrapper" className="social">
                {site_development}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Sites;

